.stepWrapper {
    background-color: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px 50px;
    z-index: 99999;
    border-top: 2px solid #005848;
}

.stepComponent {
    max-width: 1050px;
    margin: 0 auto;
}