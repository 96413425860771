.ant-btn {
    padding: 8px 12px;
    height: auto;
    border-radius: 4px;
    border: 2px solid $brand-primary;
    color: $brand-primary;
    padding: 7px 20px;
    border-radius: $border-radius-small;
    box-shadow: 1px 1px 0px $brand-primary;
    transition: all 0.25s ease-in-out;
    &:hover {
        background-color: transparentize($color: $brand-primary, $amount: 0.95);
        border-color: $brand-primary;
        color: $brand-primary;
        box-shadow: 2px 2px 0px $brand-primary;
    }
    &.ant-btn-primary {
        background-color: $brand-primary;
        color: #fff;
        border: none;
        padding: 8px 16px;
        text-transform: uppercase;
        box-shadow: 1px 1px 0px $brand-primary;
        &[disabled] {
            background-color: transparentize($color: $brand-primary, $amount: 0.2);
            color: #fff;
        }
        &.btn-normal {
            text-transform: none;
        }
        &.download-button {
            font-size: 14px;
            font-weight: bold;
            padding: 10px 24px;
            margin-top: 50px;
            .anticon {
                font-size: 20px;
            }
        }
        &.next-step-button {
            margin-top: 30px;
            font-size: 14px;
            padding: 10px 28px;
        }
    }
}
