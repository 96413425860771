.ant-steps {
    // text-align: center !important;
    margin: 0 auto !important;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background-color: $brand-secondary;
    border: none;
    font-family: "Roboto", sans-serif;
    font-size: 18px;
}

.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
    font-family: "Roboto", sans-serif;
    font-size: 18px;
}

.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title,
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title,

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title
{
    display: none;
}

.ant-steps-item-icon {
    line-height: 32px;
}