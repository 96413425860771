@import './../../styles/variables.scss';

.label {
    z-index: 1;
    font-size: 16px;
    pointer-events: none;
    transition: 0.2s ease all;
    color: #595959;
    margin: 10px 0 10px 0 ;
    display: inline-block;
    &::before {
      content: '';
      opacity: 0;
      transition: 0.2s ease all;
    }
    .hintText {
      display: block;
      border-top: 1px solid #e2e2e2;
      border-bottom: 1px solid #e2e2e2;
      margin: 6px 0 2px 0;
      padding: 6px 0;
      font-size: 12px;
      span {
        padding-right: 6px;
        display: inline-block;
        font-size: 10px;
      }
      b {
        font-weight: 600;
        color: #000;
        text-decoration: underline;
      }
    }
  }
  
  .floatingLabel {
    color: $brand-primary;
    font-weight: 600;
  }

  .dropdownIcon {
    transition: all 0.25s ease-in-out;
    &.dropdownOpen {
      transform: rotate(180deg);
    }
  }