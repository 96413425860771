$font-primary: 'Cintetype';
$font-secondary: 'Cintetype';

@font-face {
    font-family: $font-primary;
    src: url('./../fonts/sectra-regular.woff2');
}


@font-face {
    font-family: $font-secondary;
    src: url('./../fonts/cinetype-regular.woff2');
}

body {
    font-family: $font-secondary;
}

h1 {
    font-size: 28px;
    color: $brand-primary;
    font-family: $font-primary;
}

h2 {
    font-size: 26px;
    color: $brand-primary;
    font-family: $font-primary;
}