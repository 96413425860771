.ant-select {
    width: 100%;
    &:not(.ant-select-customize-input) {
        .ant-select-selector {
            border: 2px solid #e2e2e2;
            border-radius: 4px;
            height: 45px;
        }
    }
    &.ant-select-focused {
        &:not(.ant-select-disabled) {
            &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
                box-shadow: none;
                border-color: $brand-outline;
                border-right-width: 2px !important;
            }
        }
        &:not(.ant-select-customize-input) {
            .ant-select-selector {
                border: 2px solid $brand-outline;
                box-shadow: none;
                outline: none;
            }
        }
    }
    &.errored {
    &:not(.ant-select-customize-input) {
        .ant-select-selector {
            border: 2px solid $error-color
        }
    }
    }
}

.dropdown-label {
    margin-bottom: 10px;
    display: inline-block;
    margin-top: 10px;
    font-size: 16px;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    padding-top: 5px;
}

.dropdown-error-text {
    color: $error-color;
    font-size: 12px;
    padding-top: 4px;
    display: inline-block;
}