$brand-primary: #5271FF;
$brand-outline: #5271FF;
$brand-secondary: #005848;

$brand-primary-light: #D4DCFF;
$brand-secondary-light: #d4e3e0;

$brand-background: #fff;

$border-radius-large: 32px;
$border-radius-medium: 18px;
$border-radius-small: 12px;
$border-radius-x-small: 8px;


$error-color: #ff5e5e;

$global-text-color: #595959;