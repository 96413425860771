.animationWrapper {
    transition: all 0.25s ease-in-out;
    svg {
        height: 100%;
        width: 100%;
        transition: all 0.35s cubic-bezier(0.550, 0.000, 0.550, 1);
    }
    div[role="button"] {
        transition: all 0.35s cubic-bezier(0.550, 0.000, 0.550, 1);
    }
    &.downloadComplete {
        svg {
            height: 100px !important;
        }
        div[role="button"] {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            height: 125px !important;
            width: 125px !important;
        }
    }
    hr {
        border: none;
        border-top: 2px solid lighten($color: #5271FF, $amount: 25);
        margin-bottom: 20px;
        margin-top: -20px;
    }
}

.modalTitle {
    text-align: center;
    font-size: 22px;
    color: #5271FF;
}

.downloadCompleteInfo {
    position: relative;
    text-align: center;
}

.feedbackWrapper {
    margin-top: 20px;
    .thumb, .thumbsDown {
        height: 36px;
        width: 36px;
        cursor: pointer;
        display: inline-block;
        margin: 10px;
        svg {
            height: 36px;
            width: 36px;
        }
    }
    .thumb {
        &:hover {
            svg.unfilled {
                fill: darken($color: #5271FF, $amount: 25);
            }
        }
    }
    .thumbsDown {
        svg {
            transform: rotate(180deg);
        }
        &:hover {
            svg.unfilled {
                fill: darken($color: #5271FF, $amount: 25);
            }
        }
    }
}

.copyToClipboard {
    color: #5271FF;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
    span {
        margin-right: 5px;
    }
}

.backToHome {
    color: #5271FF;
    cursor: pointer;
    display: inline-block;
    line-height: 0;
    margin-bottom: 20px;
    &:hover {
        text-decoration: underline;
    }
}