.hintText {
        display: block;
        border-top: 1px solid #e2e2e2;
        border-bottom: 1px solid #e2e2e2;
        margin: 6px 0 12px 0;
        padding: 6px 0;
        font-size: 12px;
        span {
          padding-right: 6px;
          display: inline-block;
          font-size: 10px;
        }
}


.floatingLabel {
    color: #066E58;
    font-weight: 600;
  }

  .dropdownIcon {
    margin-right: 25px;
    transition: all 0.25s ease-in-out;
    &.dropdownOpen {
      transform: rotate(180deg);
    }
  }