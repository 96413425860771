input.ant-input, .ant-input-number {
    border-radius: $border-radius-x-small;
    border: 2px solid #e2e2e2;
    &:focus {
      box-shadow: none;
      border-radius: $border-radius-x-small;
      border: 2px solid $brand-primary !important;
    }
    &.errored {
      border: 2px solid $error-color;
    }
  }
  
.ant-input-number {
  width: 100%;
  .ant-input-number-handler-wrap {
    display: none;
  }
  .ant-input-number-input-wrap, input {
    height: 42px;
  }
}

.ant-input-number-focused {
  border: 2px solid $brand-primary !important;
  box-shadow: none;
}

.date-picker-wrapper {
  margin-top: 9px;
  span.date-picker-label {
    font-size: 16px;
    margin-bottom: 10px;
    display: inline-block;
  }
  .ant-picker {
    border-radius: $border-radius-x-small;
    border: 2px solid #e2e2e2;
    width: 100%;
    padding: 10px 11px;
    &:hover {
      border-color: #40a9ff;
    }
    &.ant-picker-focused {
      border: 2px solid $brand-primary !important;
      box-shadow: none;
    }
    &.errored {
      border-color: $error-color;
    }
  }
  .date-picker-error-text {
    color: $error-color;
    font-size: 12px;
    padding-top: 4px;
    display: inline-block;
  }
}

.ant-form-item {
  &.compact-form {
    margin-bottom: 10px;
  }
}
.ant-form-item-control-input {
  textarea {
    border-radius: $border-radius-x-small;
    border: 2px solid #e2e2e2;
    &:focus {
      border: 2px solid $brand-primary !important;
      box-shadow: none;
    }
    &.errored {
      border-color: $error-color;
    }
  }
  .error-text {
    color: $error-color;
    font-size: 12px;
    padding-top: 4px;
    display: inline-block;
  }
}

button.ant-switch {
  height: 30px;
  .ant-switch-inner {
    margin: 0 8px 0 32px
  }
  .ant-switch-handle {
    height: 26px;
    width: 26px;
    &::before {
      border-radius: 26px;
    }
  }
  &.ant-switch-checked {
    background-color: $brand-primary;
    .ant-switch-inner {
      margin: 0 32px 0 8px;
    }
    .ant-switch-handle {
      left: calc(100% - 26px - 2px);
    }
  }
}


.login-input {
  input.ant-input, .ant-input-number {
    border-radius: initial !important;
    border: initial !important;
    &:focus {
      box-shadow: none;
      border-radius: $border-radius-x-small !important;
      border: none !important;
    }
    &.errored {
      border: 2px solid $error-color  !important;
    }
  }
}

.login-button {
  height: 100% !important;
  span {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }

  &[disabled] {
    background-color: transparentize($color: $brand-primary, $amount: 0.2) !important;
    border-color: transparentize($color: $brand-primary, $amount: 0.2) !important;
    color: #fff;
}
}