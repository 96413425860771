.document-card {
    border-radius: 6px;
    background-color: #ffffff;
    border-radius: $border-radius-medium;
    padding: 0;
    box-shadow: 2px 2px 4px 1px rgb(0 0 0 / 3%);
    border: 2px solid $brand-primary;
    cursor: pointer;
    position: relative;
    .ant-card-body {
        padding: 30px 30px;
    }
    h3 {
        font-family: $font-primary;
        color: $brand-primary;
        font-size: 18px;
    }
    &:hover {
        box-shadow: 1px 1px 0px 0px transparentize($color: $brand-primary, $amount: 0);
    }
    .checkbox {
        position: absolute;
        top: 15px;
        right: 15px;
        height: 16px;
        width: 16px;
        border-radius: 50%;
        border: 2px solid $brand-primary;
    }
    &.selected {
        box-shadow: 2px 2px 0px 0px transparentize($color: $brand-primary, $amount: 0);
        .checkbox {
            background-color: $brand-primary;
            &:before{
                content:"";
                position: absolute;
                width:2px;
                height:9px;
                background-color:#fff;
                left:6px;
                top:2px;
                transform: rotate(45deg);
            }

            &:after{
                content:"";
                position: absolute;
                width:2px;
                height:4px;
                background-color:#fff;
                left:1.5px;
                top:6px;
                transform: rotate(-45deg);
            }
        }
    }
}

.restore-prompt {
    padding: 10px 40px;
    border-radius: 6px;
    border: 2px solid $brand-primary;
    background-color: transparentize($color: $brand-primary, $amount: 0.9);
    position: relative;
    margin-bottom: 30px;
    h5 {
        font-size: 16px;
        color: $brand-primary;
        margin: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }
    .ant-btn {
        padding: 4px 12px;
        border-radius: 4px;
        margin: 0 10px;
        &.ant-btn-primary {
            text-transform: none;
        }
    }
}