.ant-table {
    border-radius: 6px;
    .ant-table-container {
        border: 2px solid #5271FF;
        border-radius: 6px;
        .ant-table-content {
            border-radius: 6px;
        }
    }
    table {
        thead {
            th {
                border-bottom-color: #5271FF;
                &:first-child {
                    border-top-left-radius: 6px !important;
                }
                &:last-child {
                    border-top-right-radius: 6px !important;
                }
                &:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
                    background-color: #5271FF !important;
                }
            }
        }
    }
}