.form-wrapper {
  margin: 5vh 0;
  max-width: 1050px;
  min-height: calc(70vh - 60px);
  margin-left: auto;
  margin-right: auto;
  background-color: #ffffff;
  border-radius: $border-radius-large;
  padding: 60px 100px;
  // box-shadow: 2px 2px 4px 1px rgb(0 0 0 / 3%);
  border: 2px solid $brand-primary;
  // box-shadow: 2px 2px 4px 1px rgb(0 0 0 / 3%);
  box-shadow: 4px 4px 0px 0px transparentize($color: $brand-primary, $amount: 0);

  // background-color: $brand-primary;
  h1 {
    margin-bottom: 30px;
  }

  .step-actions {
    margin-top: 50px;
  }
}

.document-select-wrapper {
  margin-top: 15vh;
  max-width: 1050px;
  margin-left: 33vw;
  padding: 0 5vw;
  .secondary-logo {
    height: 30px;
    width: 208px;
    background-repeat: no-repeat;
    background: url('./../assets/logo.svg');
    position: absolute;
    top: -8px;
    left: calc(66vw - 104px);
    margin-top: 40px;
  }
}
.select-screen-sidebar {
  display: block;
    height: 100vh;
    width: 33vw;
    position: fixed;
    background: $brand-secondary-light;
    top: 0;
    left: 0;
    padding: 5vh 3vw;
    h1 {
      color: $brand-secondary;
      font-weight: 700;
      font-size: 32px;
      text-align: center;
    }
    .document-illustration {
      width: 100%;
      height: 100%;
      background: url('./../assets/generator_page.png');
      position: absolute;
      left: 0;
      bottom: 0;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
    }
}

.info-row {
  margin-bottom: 30px;
}

.hint-override-switch-wrapper {
  border: 2px solid #e2e2e2;
  padding: 10px 12px;
  border-radius: $border-radius-x-small;
  background-color: #f5f5f5;
  .switch-text {
    margin-right: 15px;
    display: inline-block;
  }
}

.app-header {
  height: 60px;
  width: 100vw;
  display: flex;
  flex-direction: inherit;
  justify-content: space-between;
  .samvad-logo {
    background: url('./../assets/logo.svg');
    height: 30px;
    width: 180px;
    background-repeat: no-repeat;
    margin-top: 15px;
    margin-left: 20px;
  }
  .profile-section {
    padding: 20px 30px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    span.user-email {
      position: relative;
      font-size: 14px;
      &:before {
        content: '';
        height: 25px;
        width: 25px;
        position: absolute;
        border-radius: 50%;
        background-color: #5271FF;
        left: -35px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    span.logout-button {
      color: #5271FF;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.layout-adjuster {
  padding-bottom: 100px;
}

.login-wrapper {
  max-width: 75%;
  // background: #5271fe;
  // color: #fff;
  border: 2px solid #5271FF;
  box-shadow: 2px 2px 0px 0px #5271ff;
  margin: 0 auto;
  border-radius: 16px;
  padding: 50px 80px;
  text-align: center;
  h3 {
    color: #5271FF;
    margin-bottom: 50px;
    font-size: 22px;
    font-weight: 700;
  }
  button {
    background-color: #5271FF !important;
    color: #fff !important;
    border-radius: 6px !important;
    padding: 2px 4px !important;
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 6px !important;
    }
  }
}

@media screen and (max-width: 1024px) {
  .form-wrapper {
    max-width: 800px;
    padding: 45px 50px;
  }
}

.finish-step-previous-button {
  margin-top: 50px;
}

.admin-dashboard-route {
  color: #5271FF;
  position: absolute;
  cursor: pointer;
  font-size: 18px;
  top: 40px;
  &:hover {
    text-decoration: underline;
    color: darken($color: #5271FF, $amount: 20);
  }
}

.admin-report-wrapper {
  max-width: 1050px;
  margin: 0 auto;
  margin-top: 50px;
}

.help-cta {
  height: 50px;
  width: 50px;
  border-radius: 60px;
  box-shadow: 2px 2px 4px 2px rgba($color: #000000, $alpha: 0.08);
  position: fixed;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  svg {
    height: 25px;
    width: 25px;
  }
  &:hover {
    transition: all 0.2s ease-in-out;
    box-shadow: 4px 4px 4px 2px rgba($color: #000000, $alpha: 0.08);
  }
}